<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <div style="margin-bottom: 20px">
          <span>2022-01-20 以后的订单修改记录请</span>
          <span @click="goquery()" class="goquery">点此返回</span>
        </div>
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Refund_order_number')">
                <el-input
                  v-model="formInline.orderNumber"
                  placeholder="请输入内容"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <el-form-item label="申请人:">
                <el-autocomplete
                  class="inline-input"
                  v-model="formInline.bossManagerName"
                  :fetch-suggestions="querySearchAsync1"
                  placeholder="请输入内容"
                  value-key="bossManagerName"
                  :trigger-on-focus="false"
                  @select="handleSelect1"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="车牌号"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Refund_status')">
                <el-select
                  v-model.trim="formInline.refundState"
                  filterable
                  size="8"
                  style="width: 130px"
                  placeholder="全部"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in causeDownData"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="退款类型:">
            <el-select v-model="formInline.refundType" placeholder="请选择">
              <el-option
                v-for="item in refundTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Payment_method')">
                <el-select
                  v-model.trim="formInline.payType"
                  filterable
                  size="8"
                  style="width: 150px"
                  placeholder="全部"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="item.payTypeName"
                    :value="item.payType"
                    :key="item.payType"
                    v-for="item in payTypeStatus"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_channels')" prop="channelType">
                <el-select v-model.trim="formInline.channelType">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.channelTypeDesc"
                    :value="value.channelType"
                    :key="value.channelType"
                    v-for="value in channelList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_devices')" prop="devType">
                <el-select v-model.trim="formInline.devType">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in plantList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model.trim="timeType"
                  filterable
                  size="8"
                  style="width: 150px; margin-right: 10px"
                >
                  <el-option
                    :label="item.label"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in Time"
                  ></el-option>
                </el-select>
                <!-- <el-date-picker v-model="date1"
                            type="datetime"
                            placeholder="选择日期"
                            @change="showLog"> </el-date-picker> 至
            <el-date-picker v-model="date2"
                            type="datetime"
                            placeholder="选择日期"
                            @change="showLog" style="margin-right: 10px"> </el-date-picker> -->
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  style="display: inline-block"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.tabs.refundHistory.button.export"
          ></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                type=""
                icon="el-icon-upload2"
                @click="exportFn"
                :loading="loading"
                v-if="$route.meta.authority.tabs.refundHistory.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB20">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="90">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0"
                @click="fn(scope)"
                v-if="$route.meta.authority.button.detail || true"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 弹窗 -->
      <el-dialog
        title="退款记录"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="80%"
      >
        <div>
          <h1 class="titleh1">停车记录</h1>
          <el-table :data="tableData6" style="width: 100%">
            <el-table-column
              align="center"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols6"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table>
          <h1 class="titleh1">支付记录</h1>
          <el-table v-loading="loading" :data="refundrRecord" style="width: 100%">
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols3"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table>
          <el-form ref="form" :model="form" label-width="100px" class="recordForm">
            <el-form-item label="退款原因:">
              <span>{{ form.refundReasonName }}</span>
            </el-form-item>
            <el-form-item label="退款方式:">
              <span>原路退回</span>
            </el-form-item>
            <!--实际出场时间-->
            <el-form-item label="实际出场时间:" v-show="form.region === 0">
              <span>{{ form.exitTime }}</span>
            </el-form-item>
            <el-form-item label="退款计算:">
              <el-table :data="moneyShow" style="width: 100%">
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <p v-show="scope.$index == 0">原订单</p>
                    <p v-show="scope.$index == 1">修改后</p>
                    <p v-show="scope.$index == 2">退款</p>
                    <!--<p>原订单</p>-->
                    <!--<p>修改后</p>-->
                    <!--<p>退款</p>-->
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="date" label="应付金额">
                  <template slot-scope="scope">
                    <p v-if="form.refundReason == 0 && scope.$index == 0">
                      {{ scope.row.shouldPayMoney ? (actualPayMoneyVal / 100).toFixed(2) : "0.00" }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 1">
                      {{
                        scope.row.shouldPayMoney != undefined
                          ? (scope.row.shouldPayMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                    <!--<p v-if="form.region == 1 && scope.$index == 2">{{scope.row.shouldPayMoney ? (scope.row.shouldPayMoney / 100).toFixed(2) : ''}}</p>-->
                    <p v-if="form.refundReason == 1 && scope.$index == 0">
                      {{
                        scope.row.shouldPayMoney
                          ? (scope.row.shouldPayMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 0">
                      {{
                        scope.row.shouldPayMoney
                          ? (scope.row.shouldPayMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <!--<input style="width: 80%" type="text" v-model="scope.row.shouldPayMoney" v-if="form.region === 2 && scope.$index === 2">-->
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="name" label="停车卡抵扣">
                  <template slot-scope="scope">
                    <p v-if="form.refundReason == 0 && scope.$index == 0">
                      {{
                        scope.row.parkCardMoney
                          ? (scope.row.parkCardMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 1">
                      {{
                        scope.row.parkCardMoney != undefined
                          ? (scope.row.parkCardMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 2">
                      {{
                        currentChargeVO.parkCardMoney != undefined
                          ? Number(
                              (originalChargeVO.parkCardMoney - currentChargeVO.parkCardMoney) / 100
                            ).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                    <p v-if="form.refundReason == 1 && scope.$index == 2">
                      {{
                        scope.row.parkCardMoney
                          ? (scope.row.parkCardMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 0">
                      {{
                        scope.row.parkCardMoney
                          ? (scope.row.parkCardMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 0">
                      {{
                        scope.row.parkCardMoney
                          ? (scope.row.parkCardMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 2">
                      {{ otherReason.parkCardMoney }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="address" label="优惠券金额">
                  <template slot-scope="scope">
                    <!--<p v-if="form.region !== 2|| scope.$index !== 2">{{ scope.row.couponMoney }}</p>-->
                    <p v-if="form.refundReason == 0 && scope.$index == 0">
                      {{
                        scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 1">
                      {{
                        scope.row.couponMoney != undefined
                          ? (scope.row.couponMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 2">
                      {{
                        currentChargeVO.couponMoney != undefined
                          ? Number(
                              (originalChargeVO.couponMoney - currentChargeVO.couponMoney) / 100
                            ).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                    <p v-if="form.refundReason == 1 && scope.$index == 2">
                      {{
                        scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 0">
                      {{
                        scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 0">
                      {{
                        scope.row.couponMoney ? (scope.row.couponMoney / 100).toFixed(2) : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 2">
                      {{ otherReason.couponMoney }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="address" label="车场折扣金额">
                  <template slot-scope="scope">
                    <p v-if="form.refundReason == 0 && scope.$index == 0">
                      {{
                        scope.row.discountMoney
                          ? (scope.row.discountMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 1">
                      {{
                        scope.row.discountMoney != undefined
                          ? (scope.row.discountMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 2">
                      {{
                        currentChargeVO.discountMoney != undefined
                          ? Number(
                              (originalChargeVO.discountMoney - currentChargeVO.discountMoney) / 100
                            ).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 0">
                      {{
                        scope.row.discountMoney
                          ? (scope.row.discountMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                    <p v-if="form.refundReason == 1 && scope.$index == 2">
                      {{
                        scope.row.discountMoney
                          ? (scope.row.discountMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 0">
                      {{
                        scope.row.discountMoney
                          ? (scope.row.discountMoney / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 2">
                      {{ otherReason.discountMoney }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="address" label="实付金额">
                  <template slot-scope="scope">
                    <p v-if="form.refundReason == 0 && scope.$index == 0">
                      {{ scope.row.money ? (actualPayMoneyVal / 100).toFixed(2) : "0.00" }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 1">
                      {{
                        scope.row.money != undefined ? (scope.row.money / 100).toFixed(2) : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 0 && scope.$index == 2">
                      {{
                        currentChargeVO.money != undefined
                          ? Number((actualPayMoneyVal - currentChargeVO.money) / 100).toFixed(2)
                          : "0.00"
                      }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 0">
                      {{ (scope.row.money / 100).toFixed(2) }}
                    </p>
                    <p v-if="form.refundReason == 1 && scope.$index == 1">0.00</p>
                    <p v-if="form.refundReason == 1 && scope.$index == 2">
                      {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 0">
                      {{ scope.row.money ? (scope.row.money / 100).toFixed(2) : "0.00" }}
                    </p>
                    <p v-if="form.refundReason == 2 && scope.$index == 2">
                      {{ otherReason.money }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="备注:">
              <span>{{ form.remarks }}</span>
            </el-form-item>
          </el-form>
          <h1 class="titleh1">退款操作</h1>
          <el-table :data="tableData5" style="width: 100%">
            <el-table-column :label="$t('list.index')" type="index" width="50"> </el-table-column>
            <el-table-column
              align="center"
              v-show="
                item.prop != 'remarks' ||
                (tableData2[0] && tableData2[0].refundStateName == '已驳回')
              "
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols5"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import InfoList from "@/components/infoList/infoList";
export default {
  name: "record",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len >= 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      defalutDate: [],
      moneyShow: [],
      refundTableData: [],
      showParkRecord: false,
      timeType: 1,
      Time: [
        { label: this.$t("list.Application_Time"), code: 1 },
        {
          label: this.$t("list.Refund_time"),
          code: 2,
        },
      ],
      form: {
        orderNumber: "",
        region: "",
        date1: "原路退回",
        date2: "",
        date3: "",
        date4: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        value1: "",
        payType: "",
      },
      orderMoney: "",
      causeData: "",
      i: 1,
      radio: "1",
      tableData2: [],
      tableData4: [],
      tableData5: [],
      tableData6: [],
      flag: false,
      selectFlag: true,
      plateNumber: "",
      windowplateNumber: "",
      rowData: 0,
      infoSize: 4,
      isShow: false,
      dialogVisible: false, // todo
      infoDetail: {},
      radio3: "停车记录信息",
      isPublic: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      value5: "",
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      tableOrderdata: [],
      ExitTypeList: [],
      roleList: [],
      causeDownData: [],
      payTypeStatus: [],
      otherReason: {},
      parkRecordId: "",
      paymentId: "",
      payType: "",
      carId: "",
      berthId: "",
      parkId: "",
      entryTime: "",
      exitTime: "",
      balance: "",
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "orderNumber",
          label: "退款订单编号",
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.applicant"),
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Application_Time"),
          width: "",
        },
        {
          prop: "parentParkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber) {
              if (row.plateNumber.indexOf("无") != -1) {
                return "无牌车";
              } else {
                return row.plateNumber;
              }
            } else {
              return "";
            }
          },
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          formatter: (row) => {
            return row.entryTime ? dateFormat(new Date(row.entryTime * 1)) : "";
          },
        },
        {
          prop: "exitTime",
          label: this.$t("list.leaving_time"),
          formatter: (row) => {
            return row.exitTime ? dateFormat(new Date(row.exitTime * 1)) : "";
          },
        },
        {
          prop: "channelTypeDesc",
          label: this.$t("list.Payment_channels"),
          width: "",
        },
        {
          prop: "devTypeDesc",
          label: this.$t("list.Payment_devices"),
          width: "",
        },
        {
          prop: "orderShouldPay",
          label: this.$t("list.order_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.orderShouldPay) {
              return Number(row.orderShouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualRefundPay",
          label: this.$t("list.refund_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "refundReasonName",
          label: this.$t("list.refund_reason"),
          width: "",
        },
        {
          prop: "refundStateName",
          label: "退款状态",
          width: "",
        },
        //  {
        //   prop: 'refundType',
        //   label: '退款类型',
        //   width: ''
        // },
        {
          prop: "refundTime",
          label: this.$t("list.Refund_time"),
          formatter: (row) => {
            return row.refundTime ? dateFormat(new Date(row.refundTime * 1)) : "";
          },
        },
      ],
      tableOrder: [
        {
          label: "订单编号",
          prop: "payOrderId",
          width: "200",
        },
        {
          label: this.$t("list.payment_time"),
          prop: "payTime",
          width: "150",
        },
        {
          label: "支付方式",
          prop: "payTypeDesc",
          formatter: (row, column) => {
            for (let i = 0; i < this.payTypeStatus.length; i++) {
              if (this.payTypeStatus[i].code === row.payType) {
                return this.payTypeStatus[i].desc;
              }
            }
          },
        },
        {
          label: this.$t("list.order_amount"),
          prop: "money",
          width: "100",
        },
        {
          label: "支付流水号",
          prop: "paymentId",
          width: "",
        },
      ],
      tableData: [],
      parkDetail: [],
      channelList: [],
      plantList: [],
      refundrRecord: [],
      originalChargeVO: "",
      currentChargeVO: "",
      rowData2: 0,
      formInline: {
        // 退款订单号
        orderNumber: "",
        // 申请人
        operator: "",
        // 车牌号
        plateNumber: "",
        // 停车场名称
        parentParkId: "",
        // 退款状态
        refundState: "",
        // 支付渠道
        channelType: "",
        // 支付设备
        devType: "",
        // 支付方式
        payType: "",
        // 时间段 开始 退费
        startRefundTime: "",
        // 时间段 结束 退费
        endRefundTime: "",
        // 时间段 结束 申请
        startTime: "",
        // 时间段 结束 申请
        endTime: "",
      },
      tableCols4: [
        {
          prop: "refundModeName",
          label: this.$t("list.Refund_method"),
        },
        {
          prop: "payType",
          label: "支付方式",
          formatter: (row, column) => {
            for (let i = 0; i < this.payTypeStatus.length; i++) {
              if (this.payTypeStatus[i].code === row.payType) {
                return this.payTypeStatus[i].desc;
              }
            }
          },
        },
        {
          prop: "actualRefundPay",
          label: this.$t("list.refund_amount"),
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "refundReasonName",
          label: this.$t("list.refund_reason"),
        },
        {
          prop: "actualExitTime",
          label: this.$t("list.Actual_appearance_time"),
          formatter: (row) => {
            return row.actualExitTime ? dateFormat(new Date(row.actualExitTime * 1)) : "";
          },
        },
        {
          prop: "remarks",
          label: "备注",
        },
        {
          prop: "operatorName",
          label: this.$t("list.applicant"),
          width: "100",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Application_Time"),
          width: "100",
        },
        {
          prop: "refundStateName",
          label: this.$t("list.state"),
        },
      ],
      tableCols5: [
        // {
        //   prop: 'index',
        //   label: '序号',
        //   width: '100'
        // },
        {
          prop: "createdTime",
          label: "操作日志",
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "sortNumName",
          label: "审批记录",
          width: "",
        },
        {
          prop: "remarks",
          label: "驳回原因",
          width: "",
        },
      ],
      tableCols3: [
        {
          prop: "payOrderId",
          label: "订单编号",
          width: "",
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
        {
          prop: "payType",
          label: "支付方式",
          formatter: (row, column) => {
            for (let i = 0; i < this.payTypeStatus.length; i++) {
              if (this.payTypeStatus[i].payType === row.payType) {
                return this.payTypeStatus[i].payTypeName;
              }
            }
          },
        },
        {
          prop: "shouldPay",
          label: "应付金额",
          width: "",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "agioPay",
          label: this.$t("list.preferential_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.agioPay) {
              return Number(row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "money",
          label: this.$t("list.order_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "paymentId",
          label: "支付流水号",
          width: "",
        },
      ],
      tableCols6: [
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber) {
              if (row.plateNumber.indexOf("无") != -1) {
                return "无牌车";
              } else {
                return row.plateNumber;
              }
            } else {
              return "";
            }
          },
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.leaving_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "actualPay",
          label: this.$t("list.Actual_received_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.actualPay) {
              return Number(row.actualPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
      actualPayMoneyVal: "",
      refundTypeList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "手动申请退款",
          value: "1",
        },
        {
          label: "系统申请退款",
          value: "2",
        },
      ],
    };
  },
  watch: {
    tableData2: {
      handler(curVal, oldVal) {
        if (curVal.length > 0) {
          this.showParkRecord = this.tableData2[0].refundReasonName == "出场延时";
        }
      },
      deep: true,
    },
  },
  methods: {
    goquery() {
      this.$router.push({
        path: "/refundManagement",
      });
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 获取渠道列表
    getChannelList() {
      this.$axios.get("/acb/2.0/payOrder/payChannelTypeDictList").then((res) => {
        if (res.state == 0) {
          this.channelList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取设备列表
    getPlantList() {
      this.$axios.get("/acb/2.0/payOrder/devTypeList").then((res) => {
        if (res.state == 0) {
          this.plantList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleSelect1(item) {
      this.formInline.bossManagerId = item.bossManagerId;
      this.formInline.bossManagerName = item.bossManagerName;
    },
    // 导出所有
    exportFn() {
      exportExcelNew("/acb/2.0/refundRecord/report", this.formInline);
    },
    // 计算实际收费
    calculate() {
      this.$axios
        .get("/acb/2.0/feeScene/charge/calc", {
          data: {
            carId: this.carId,
            berthId: this.berthId,
            parkId: this.parkId,
            entryTime: this.entryTime,
            exitTime: this.exitTime,
            parkRecordId: this.parkRecordId,
          },
        })
        .then((res) => {
          this.form.date2 = res.value.money;
        });
    },
    // 退款原因
    cause(item) {
      this.causeData = item === "出场延迟" ? 0 : item === "车牌扣错" ? 1 : 2;
    },
    refundRecordDetail(scope) {
      this.moneyShow = [];
      this.$axios.get("/acb/2.0/refundRecord/getById/" + scope.row.refundRecordId).then((res) => {
        this.form = res.value;
        this.currentChargeVO = res.value.currentChargeVO;
        this.originalChargeVO = res.value.originalChargeVO;
        this.moneyShow.push(res.value.originalChargeVO);
        this.moneyShow.push(res.value.currentChargeVO);
        this.moneyShow.push(res.value.originalChargeVO);
        if (this.form.refundReason == 2) {
          this.otherReason.money = Number(res.value.actualRefundPay / 100).toFixed(2); // 实付金额
          this.otherReason.discountMoney = Number(res.value.parkDiscountRefundPay / 100).toFixed(2); // 车场折扣金额
          this.otherReason.parkCardMoney = Number(res.value.parkCardRefundPay / 100).toFixed(2); // 停车卡抵扣金额
          this.otherReason.couponMoney = Number(res.value.agioRefundPay / 100).toFixed(2); // 优惠券金额
          this.moneyShow.splice(2, 1, this.otherReason);
        }
      });
    },
    fn(scope) {
      this.dialogVisible = true;
      this.tableData2 = [];
      this.tableData2.push(scope.row);
      this.rowData = scope.row.payOrderId;
      this.rowData2 = scope.row.refundRecordId;
      this.windowplateNumber = scope.row.plateNumber;
      this.parkRecordId = scope.row.parkRecordId;
      this.carId = scope.row.carId;
      this.berthId = scope.row.berthId;
      this.parkId = scope.row.parkId;
      this.entryTime = scope.row.strEntryTime;
      this.exitTime = scope.row.strExitTime;
      this.getData();
      this.log();
      this.refundRecordIdFn();
      this.refundRecordDetail(scope);
    },
    // 停车记录
    refundRecordIdFn() {
      this.$axios.get("/acb/2.0/parkRecord/" + this.parkRecordId).then((res) => {
        this.tableData6 = [];
        this.tableData6.push(res.value);
        this.actualPayMoneyVal = this.tableData6[0].actualPay;
        console.log(this.actualPayMoneyVal);
      });
    },
    // 日志
    log() {
      this.$axios.get("/acb/2.0/refundRecord/queryById/" + this.rowData2).then((res) => {
        this.tableData5 = setIndex(this.pageNum, res.value);
      });
    },
    getCurrentRow(a, b) {
      this.orderMoney = "";
      this.orderMoney = a.money;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    getData() {
      this.$axios
        .get("/acb/2.0/refundRecord/getpayOrderById?payOrderId=" + this.rowData)
        .then((res) => {
          this.refundrRecord = [];
          this.refundrRecord.push(res.value);
        });
    },
    causeDown() {
      this.$axios.get("/acb/2.0/refundRecord/spinner").then((res) => {
        this.causeDownData = res.value.refundStateType;
      });
    },
    querySearchAsync1(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/bossManager/list", {
          data: {
            page: 0,
            size: 20,
            bossManagerName: queryString,
          },
        })
        .then((res) => {
          if (res.value.list.length > 0) {
            // this.formInline.operator = res.value.list[0].bossManagerName;
            this.formInline.bossManagerId = res.value.list[0].bossManagerId;
          } else {
            this.formInline.bossManagerId = "";
          }
          cb(res.value.list);
        });
    },
    completeValue(item) {
      if (item) {
        // parentParkId
        this.formInline.parentParkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parentParkId = "";
        this.formInline.parkName = "";
      }
    },
    plateNumberSelect(item) {},
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    onTopClick() {
      window.location.hash = "#order";
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    detailShow(data) {
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(data);
      this.getOrder();
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    clildrenHandleCurrentChange(val) {
      this.pageNum = val;
      this.getOrder();
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      // if (this.formInline.areaId == '') {
      //   this.areaList2 = [];
      //   this.formInline.streetId = '';
      // }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: this.formInline.operationId,
            areaIds:
              this.formInline.streetId != "" ? this.formInline.streetId : this.formInline.areaId,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 获取车场类型 */
    // getPark() {
    //   let opt = {
    //     url: '/acb/2.0/parkType/list',
    //     method: 'get',
    //     data: {},
    //     success: (res) => {
    //       if (res.state == 0) {
    //         this.parkType = res.value;
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    clearSearchOpt(data) {
      this.parkData();
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {});
    },
    showLog() {
      console.log(2);
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searParkRecordListFun() {
      this.formInline.operator = this.formInline.bossManagerId;
      let opt = {
        method: "get",
        url: "/acb/2.0/refundRecord/list",
        data: {
          page: this.pageNum,
          size: this.pageSize,
          ...this.formInline,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 搜索
    searParkRecordList() {
      console.log("---");
      console.log(this.formInline.plateNumber);
      console.log(this.formInline);
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
        this.loading = false;
      }
      if (this.formInline.bossManagerName == "") {
        this.formInline.bossManagerId = "";
      }
      console.log(1);
      let flag = this.showLog();
      console.log(flag);
      this.$refs["searchWrapper"].validate((v) => {
        console.log(v);
        if (v) {
          console.log(flag);
          if (flag) {
            this.loading = true;
            this.flag = false;
            this.$refs.parkInput.setShowVal(this.formInline.parkName);
            this.searParkRecordListFun();
          }
        }
      });
    },
    // 订单记录
    getOrder() {
      let page = this.pageNum || 1;
      let opt = {
        url: "/acb/2.0/parkRecord/payOrder/" + this.rowData,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value.list;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 导出
    exportFile() {
      exportExcelNew("/acb/2.0/gplus/chargePayment/exportUserPayments", this.formInline, "get");
    },
    // 提交前验证
    submitValidation() {
      if (this.form.date3 > this.orderMoney || this.form.date3 === "" || this.form.date3 <= 0) {
        this.$alert("退款金额,不能大于订单金额，不能为空或小于等于0", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      } else {
        this.submit();
      }
    },
    // 提交
    submit() {
      this.dialogVisible = false;
      this.$axios
        .post("/acb/2.0/refundRecord/create", {
          data: {
            // 实际出场时间  可否
            actualExitTime: this.form.value1
              ? dateFormat(this.form.value1, "yyyy-MM-dd HH:mm:ss")
              : "",
            // 退款金额  计算的金额
            refundPay: parseInt(this.orderMoney * 100),
            // 实际退款金额actualRefundPay  输入的金额
            actualRefundPay: parseInt(this.form.date3 * 100),
            // 退款原因
            refundReason: this.form.region,
            // 备注描述
            remarks: this.form.date4,
            // 停车记录id parkRecordId
            parkRecordId: this.parkRecordId,
            // 支付记录id paymentId
            payOrderId: this.paymentId,
            // 车牌号 plateNumber
            plateNumber: this.windowplateNumber,
            // 支付类型 payType
            payType: this.payTyperes,
          },
        })
        .then((res) => {});
    },
    payTypeFn() {
      this.$axios.get("/acb/2.0/payment/payTypeDictList").then((res) => {
        this.payTypeStatus = res.value;
      });
    },
  },
  components: {
    myComponent,
    timeRangePick,
  },
  created() {
    // this.$route.meta.keepAlive = true;
    this.getArea();
    this.getRoleList();
    this.getExitType();
    this.getChannelList();
    this.getPlantList();
    // 支付类型
    this.payTypeFn();
    // 退款原因跟下拉
    this.causeDown();
    // this.parkData()
    this.getParkSpinner();
    if (this.$route.query.memberId) {
      //  this.formInline.startTime = this.$route.query.createdTime ? this.$route.query.createdTime : this.$route.query.bindTime ? this.$route.query.bindTime : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.formInline.startTime = this.$route.query.bindTime
        ? this.$route.query.bindTime
        : this.$route.query.createdTime
        ? this.$route.query.createdTime
        : dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.formInline.endTime = dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.date1 = this.formInline.startTime;
      this.date2 = this.formInline.endTime;
      if (this.$route.query.plateNumber) {
        this.plateNumber =
          this.$route.query.plateNumber.split(",").length > 1 ? "" : this.$route.query.plateNumber;
      }
      this.formInline.plateNumber = this.plateNumber;
      this.formInline.mobile = this.$route.query.mobile;
      this.flag = true;
      this.searParkRecordListFun();
    }
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
  },
  mounted() {
    console.log(this.$route.meta.authority.tabs.refundHistory.button.export);
  },
  computed: {},
};
</script>
<style>
.recordForm .el-form-item__label {
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
  font-size: 15px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.conborder
  border-top 1px solid #C0CCDA
  background #FFFFFF
  overflow hidden
  padding 10px
.content
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      border 1px solid #0099FA
      border-radius 4px
      color #0099FA
      padding 8px 13px
      cursor pointer
      .iconfont
        margin-right 14px
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.practicalCome
  display flex
.el-radio__label
  display inline-block
  display none
.is-leaf
  text-align center
  .titleh1
    margin 30px 0
    font-size 18px
.titleh1
  margin 15px 10px
  font-size 15px
.goquery {
  color: blue;
  cursor: pointer;
}
</style>
